import localForage from 'localforage';
import _ from 'lodash'
import { postToCrmServer, postToBaseInfo1Server } from './common';
/**
 * 获取企业列表
 */
export async function listCompany(obj) {
    return await postToCrmServer('/company/list-company', obj);
}
/**
 * 搜索企业
 */
export async function searchCompany(obj) {
    return await postToCrmServer('/company/search-company', obj);
}
/**
 * 获取企业详情
 */
export async function detailCompany(obj) {
    return await postToCrmServer('/company/detail-company', obj);
}
/**
 * 新增企业
 */
export async function addCompany(obj) {
    return await postToCrmServer('/company/add-company', obj);
}
/**
 * 修改企业
 */
export async function updateCompany(obj) {
    return await postToCrmServer('/company/update-company', obj);
}
/**
 * 删除企业
 */
export async function delCompany(obj) {
    return await postToCrmServer('/company/del-company', obj);
}
/**
 * 分配跟进人员
 */
export async function assignBd(obj) {
    return await postToCrmServer('/company/assign-bd', obj);
}
/**
 * 写跟进日志
 */
export async function addLog(obj) {
    return await postToCrmServer('/follow-log/add-log', obj);
}
/**
 * 跟进日志列表
 */
export async function listLog(obj) {
    return await postToCrmServer('/follow-log/list-log', obj);
}
/**
 * 获取CRM管理员列表
 */
export async function listManager(obj) {
    return await postToCrmServer('/manager/list-manager', obj);
}
/**
 * 列表标签
 */
export async function listTag(obj) {
    return await postToCrmServer('/dict-tag/list-tag', obj);
}
/**
 * 新增标签
 */
export async function addTag(obj) {
    return await postToCrmServer('/dict-tag/add-tag', obj);
}
/**
 * 修改标签
 */
export async function updateTag(obj) {
    return await postToCrmServer('/dict-tag/update-tag', obj);
}
/*-----------------------短信推送部分----------------------------*/
/**
 * 新增短信模板
 */
export async function addMsgTpl(obj) {
    return await postToCrmServer('/promotion/add-msg-tpl', obj);
}
/**
 * 列表短信模板
 */
export async function listMsgTpl(obj) {
    return await postToCrmServer('/promotion/list-msg-tpl', obj);
}
/**
 * 预发送短信
 */
export async function preSendMsg(obj) {
    return await postToCrmServer('/promotion/pre-send-msg', obj);
}
/**
 * 发送短信
 */
export async function sendMsg(obj) {
    return await postToCrmServer('/promotion/send-msg', obj);
}
/**
 * 列表公司的发送短信历史
 */
export async function listCompSendMsg(obj) {
    return await postToCrmServer('/promotion/list-company-send-msg', obj);
}
/**
 * 列表全部公司发送短信历史
 */
export async function listSendMsg(obj) {
    return await postToCrmServer('/promotion/list-send-msg', obj);
}
/**
 * 列表同批次的公司发送短信历史
 */
export async function listCompSendMsgBatch(obj) {
    return await postToCrmServer('/promotion/list-company-send-msg-batch', obj);
}
/*-----------------------邮件推送部分----------------------------*/
/**
 * 新增邮件模板
 */
export async function addMailTpl(obj) {
    return await postToCrmServer('/promotion/add-mail-tpl', obj);
}
/**
 * 列表邮件模板
 */
export async function listMailTpl(obj) {
    return await postToCrmServer('/promotion/list-mail-tpl', obj);
}
/**
 * 预发送邮件
 */
export async function preSendMail(obj) {
    return await postToCrmServer('/promotion/pre-send-mail', obj);
}
/**
 * 发送邮件
 */
export async function sendMail(obj) {
    return await postToCrmServer('/promotion/send-mail', obj);
}
/**
 * 列表公司的发送邮件历史
 */
export async function listCompSendMail(obj) {
    return await postToCrmServer('/promotion/list-company-send-mail', obj);
}
/**
 * 列表全部公司的发送邮件历史
 */
export async function listSendMail(obj) {
    return await postToCrmServer('/promotion/list-send-mail', obj);
}
/**
 * 列表同批次的公司发送邮件历史
 */
export async function listCompSendMailBatch(obj) {
    return await postToCrmServer('/promotion/list-company-send-mail-batch', obj);
}
/**
 * 批量注册企业
 */
export async function registerCompany(obj) {
    return await postToBaseInfo1Server('/reg-comp-account', obj);
}




